































































































import {Prop, Vue} from 'vue-property-decorator';
import Component from 'vue-class-component';
import {OutputDeviceDTO, OutputLocationDTO} from '@/api/api';
import AlarmListCard from './AlarmListCard.vue';
import EventListCard from './EventListCard.vue';
import {formatApiTimeString, formatObject} from "@/utils";
import DeviceStatusIcon from "@/components/DeviceStatusIcon.vue";
import DeviceList from "@/views/main/maintenance/DeviceList.vue";
import Loading from "@/components/Loading.vue";

@Component({
  components: {
    Loading,
    DeviceList,
    DeviceStatusIcon,
    AlarmListCard,
    EventListCard,
  },
})
export default class DeviceView extends Vue {
  @Prop(Number) readonly organizationId;
  @Prop(Number) readonly locationId;
  @Prop(Number) readonly deviceId;

  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Device Type',
      sortable: true,
      value: 'device_type.name',
      align: 'left',
    },
    {
      text: 'External ID',
      sortable: true,
      value: 'external_id',
      align: 'left',
    },
    {
      text: 'Last Update',
      sortable: true,
      value: 'last_update',
      align: 'left',
    },
    {
      text: 'Status Indicator',
      sortable: true,
      value: 'status_level',
      align: 'left',
    },
    {
      text: 'Status',
      sortable: true,
      value: 'status',
      align: 'left',
    },
    {
      text: 'Data',
      sortable: false,
      value: 'data',
      align: 'left',
    },
  ];

  public device: OutputDeviceDTO|null = null;
  public location: OutputLocationDTO|null = null;

  public async mounted() {
    this.location = await this.$store.dispatch('locations/get', { locationId: this.locationId})
    this.device = await this.$store.dispatch('devices/get', { deviceId: this.deviceId })
  }

  get deviceStateDataString() {
    if (this.device === null) {
      return "";
    }

    return this.device.last_state?.data ? formatObject(this.device.last_state.data) : null;
  }

  get deviceStateString() {
    if (this.device === null) {
      return "";
    }

    return this.device.last_state?.status ?? 'Unknown';
  }

  get deviceExternalStateString() {
    if (this.device === null) {
      return "";
    }

    return this.device.last_state?.external_status ?? 'Unknown';
  }

  get deviceStateDateString() {
    if (this.device === null) {
      return "";
    }

    return this.device.last_state ? formatApiTimeString(this.device.last_state.timestamp) : null;
  }

  get deviceConfigDataString() {
    if (this.device === null) {
      return "";
    }

    return this.device.last_configuration ? formatObject(this.device.last_configuration.data) : null
  }

  get deviceConfigDateString() {
    if (this.device === null) {
      return "";
    }

    return this.device.last_configuration ? formatApiTimeString(this.device.last_configuration.timestamp) : null
  }

  get loading() {
    return this.device === null || this.location === null;
  }
}
